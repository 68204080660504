<svelte:options tag="cleandesk-full-screen-widget" />

<script>
  import { DOMAIN } from "../../config/api-variables";
  import {
    OrganisationDetails,
    selectedLanguage,
    userDetails,
  } from "../../stores/authStores";
  import ProductListing from "../ProductListing/ProductListing.svelte";
  import ChatListing from "../ChatListing/ChatListing.svelte";
  import { DeviceType } from "../../stores/authStores";
  import FullScreenChatWindow from "./components/FullScreenChatWindow.svelte";
  import { AppDefaults } from "../../config/global-enums";
  import MenuAvatar from "../Common/MenuAvatar.svelte";
  import LanguageSettingsModal from "./components/LanguageSettingsModal.svelte";
  import { onMount } from "svelte";
  import LeftSideMenu from "../Common/LeftSideMenu.svelte";
  import { createEventDispatcher } from "svelte";
  import {
    isPageView,
    selectedConversation,
    startNewAiConversation,
  } from "../../stores/chatStores";
  import MobileBottomSheet from "../../common/components/MobileBottomSheet.svelte";
  import ManageProfileDrawer from "./components/ManageProfileDrawer.svelte";
  import CampaignListing from "../CampaignListing/CampaignListing.svelte";
  import {
    listingMenuItems,
    selectedListingMenuItem,
    selectedMenuItem,
  } from "../../stores/menuStores";
  import { FetchListingMenus } from "../../utils/FetchListingMenus";
  import ListingMenuSection from "./components/ListingMenuSection.svelte";
  import FullScreenPageWindow from "./components/FullScreenPageWindow.svelte";
  import LoginModal from "./components/LoginModal.svelte";

  export let isVisible;
  export let access_by_type;
  export let access_by_id;
  export let isMobileDemo;

  const dispatch = createEventDispatcher();

  let menuWidth = $DeviceType === "mobile" ? 0 : 64;
  let isMenuExpanded = false;
  let isSecondColumnOpen = false;
  let isThirdColumnOpen = true;
  let isFourthColumnOpen = true;

  let currentTab = $DeviceType === "mobile" ? "Chat" : "Campaign";

  let langSettingsModalVisible = false;

  let loginModalVisible = false;

  let profileDrawerVisible = false;
  let selectLangMenu = false;

  $: {
    if ($DeviceType === "mobile") {
      isMenuExpanded = false;
      isSecondColumnOpen = false;
      isThirdColumnOpen = true;
      isFourthColumnOpen = false;
    }
  }

  $: if (
    ($selectedConversation || $startNewAiConversation?.startNew) &&
    $DeviceType === "mobile"
  ) {
    isSecondColumnOpen = false;
    isThirdColumnOpen = true;
    isFourthColumnOpen = false;
  }

  onMount(() => {
    // console.log("onMount");
    $DeviceType !== "mobile" && toggleMenu();

    FetchListingMenus();
  });

  const toggleMenu = () => {
    isMenuExpanded = !isMenuExpanded;
    isSecondColumnOpen = false;
    menuWidth = isMenuExpanded ? 272 : $DeviceType === "mobile" ? 0 : 64;
    // isSecondColumnOpen = $DeviceType !== "mobile" && false;
    // if ($DeviceType === "mobile" && isSecondColumnOpen === true) {
    //   isThirdColumnOpen = true;
    //   isFourthColumnOpen = true;
    // }
  };

  const toggleChatPageView = () => {
    isPageView.set(!$isPageView);
  };

  const homeClicked = () => {
    if ($DeviceType === "mobile") {
      isMenuExpanded = false;
      menuWidth = 0;
    } else {
      isMenuExpanded = true;
      menuWidth = isMenuExpanded ? 272 : 64;
    }
    isSecondColumnOpen = false;
    isThirdColumnOpen = true;
  };

  const toggleSecondColumn = () => {
    // isSecondColumnOpen = !isSecondColumnOpen;
    isSecondColumnOpen = true;
    isMenuExpanded = false;
    menuWidth = 64;
    isThirdColumnOpen = $DeviceType === "mobile" ? false : true;
    isFourthColumnOpen = $DeviceType === "mobile" ? false : true;
  };

  const closeSecondColumn = () => {
    isSecondColumnOpen = false;
    isThirdColumnOpen = true;
  };

  const toggleThirdAndFourthColumns = () => {
    isThirdColumnOpen = !isThirdColumnOpen;
    isFourthColumnOpen = !isFourthColumnOpen;
  };

  const tabItemList = [
    {
      title: "Campaign",
    },
    {
      title: "Products",
    },
  ];

  function setActiveTab(tab) {
    currentTab = tab;
  }

  let selectedMenu;

  const menuClicked = (e) => {
    console.log("menuClicked", e.detail.item);

    if (e.detail.item.id !== selectedMenu?.id) {
      if (e.detail.item?.master_menu_id === 2) {
        toggleSecondColumn();
      } else {
        if (e.detail.item.id !== 0) {
          selectedMenu = e.detail.item;
        }

        selectedMenuItem.set(e.detail.item);

        // startNewAiConversation.set({
        //   startNew: true,
        //   menu_id: e.detail.item.id,
        //   parent_menu_id: e.detail.item.parent_menu_id,
        //   menu_request_type: "intro",
        // });
      }
    }
  };

  const mobileTabs = [
    {
      title: "Chat",
    },
    {
      title: "Campaign",
    },
    {
      title: "Products",
    },
  ];

  const switchMobileTabs = (tab) => {
    if (tab.id === "chat") {
      if (isFourthColumnOpen) toggleThirdAndFourthColumns();
    } else {
      if (isThirdColumnOpen) toggleThirdAndFourthColumns();
    }
  };

  $: if ($DeviceType === "mobile" && $selectedListingMenuItem) {
    switchMobileTabs($selectedListingMenuItem);
  }
</script>

<div
  class="cleandesk-full-screen-widget"
  class:cleandesk-mobile-demo-resize={isMobileDemo}
>
  <div class="chat-header-container">
    <div
      class="chat-header"
      style="padding: 12px; display: flex; align-items: center;"
    >
      {#if $DeviceType !== "mobile"}
        <button
          style="border: none; display: flex; cursor: pointer; padding: 0 8px;"
          on:click={toggleMenu}
        >
          <svg
            class="icon-color"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            ><path
              d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"
            /></svg
          >
        </button>
      {:else}
        <button
          style="border: none; display: flex; cursor: pointer; padding: 0 8px;"
          on:click={() => {
            if (isSecondColumnOpen) {
              closeSecondColumn();
            } else {
              dispatch("closeWidget", true);
            }
          }}
        >
          <svg
            class="icon-color"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            ><path
              d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"
            /></svg
          >
        </button>
      {/if}
      <div
        class="chat-header-avatar"
        style="display: flex; align-items: center;"
      >
        {#if $OrganisationDetails?.logo}
          <img
            src={DOMAIN + $OrganisationDetails?.logo}
            alt="Avatar"
            style="height: 28px; margin-right: 8px;"
          />
        {:else}
          <img
            src={DOMAIN + "/media/default/profile/person/default.png"}
            alt="Avatar"
            style="height: 28px; border-radius: 50%; margin-right: 8px;"
          />
        {/if}
      </div>
      <div class="cleandesk-header-profile-name custom-text-color">
        <div style="display: flex; flex-direction: column;">
          {$OrganisationDetails?.title}
          {#if $OrganisationDetails?.slogan}
            <span
              class="custom-text-color"
              style="font-size: 12px; font-weight: 400;"
            >
              {$OrganisationDetails?.slogan}
            </span>
          {/if}
        </div>
      </div>

      <div class="dropdown" style="margin-left: auto"></div>

      <div
        class="cleandesk-switch-to-regular"
        style={$DeviceType === "mobile" && "gap: 0px"}
      >
        {#if $DeviceType !== "mobile"}
          <button
            class="primary-background-color custom-text-color cleandesk-switch-to-regular-button"
            on:click={() => dispatch("closeWidget", true)}
          >
            Switch to regular site
          </button>
          <button
            on:click={() => dispatch("closeWidget", true)}
            style="background: none; border: none; align-items: center; display: flex;
    padding: 1px 8px 1px 2px;"
          >
            <svg
              class="icon-color"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 384 512"
              ><path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              /></svg
            >
          </button>
        {:else}
          <!-- <MenuAvatar /> -->
          {#if $userDetails?.first_name === "Anonymous"}
            <button
              on:click={() => {
                selectLangMenu = true;
                profileDrawerVisible = true;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                class="icon-color"
                width="20px"
                ><path
                  d="m476-80 182-480h84L924-80h-84l-43-122H603L560-80h-84ZM160-200l-56-56 202-202q-35-35-63.5-80T190-640h84q20 39 40 68t48 58q33-33 68.5-92.5T484-720H40v-80h280v-80h80v80h280v80H564q-21 72-63 148t-83 116l96 98-30 82-122-125-202 201Zm468-72h144l-72-204-72 204Z"
                /></svg
              >
            </button>
          {/if}
          <button
            style="cursor: pointer;"
            on:click={() => startNewAiConversation.set({ startNew: true })}
          >
            <svg
              class="icon-color"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              ><path
                d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
              /></svg
            >
          </button>
          <!-- loginModalVisible -->
          {#if $userDetails?.first_name !== "Anonymous"}
            <button
              on:click={() => {
                if (!isMobileDemo) profileDrawerVisible = true;
              }}
            >
              <img
                src={DOMAIN + $userDetails?.profile_image}
                alt="A"
                style="height: 36px; border-radius: 50%;"
              />
            </button>
          {:else}
            <button on:click={() => (loginModalVisible = true)}>
              <svg
                class="icon-color"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
                ><path
                  d="M480-120v-80h280v-560H480v-80h280q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H480Zm-80-160-55-58 102-102H120v-80h327L345-622l55-58 200 200-200 200Z"
                /></svg
              >
            </button>
          {/if}
        {/if}
      </div>
    </div>
  </div>

  <!-- toggle tab div -->
  {#if $DeviceType === "mobile" && !isSecondColumnOpen}
    <div
      style="display: flex; justify-content: space-evenly; background-color: #fff; gap: 8px; -ms-overflow-style: none;  scrollbar-width: none; overflow-x: auto;"
    >
      {#each $listingMenuItems as tab}
        <div
          style="display: flex; justify-content: center; align-items: center; background: #fff; height: 40px;"
        >
          <button
            style="background: #f0f0f0; padding: 6px 18px; border-radius: 50px; border: none; cursor: pointer;"
            on:click={() => selectedListingMenuItem.set(tab)}
          >
            <span
              class="tab-switch-text"
              style="font-size: 16px; font-weight:{$selectedListingMenuItem?.id ===
              tab.id
                ? 700
                : 500}; color: #000;"
            >
              {tab.name}
            </span>
          </button>
        </div>
      {/each}
    </div>
  {/if}

  <div class="cleandesk-container" style={isMobileDemo && "display: flex;"}>
    <div
      class="cleandesk-menu"
      style="width: {menuWidth}px; flex: 0 0 {menuWidth}px; {!isMenuExpanded &&
      $DeviceType === 'mobile'
        ? 'display: none;'
        : ''}"
    >
      <div class="cleandesk-menu-container">
        <LeftSideMenu
          expanded={isMenuExpanded}
          {selectedMenu}
          on:expandMenu={toggleMenu}
          on:menuItemClick={menuClicked}
        />
      </div>

      <div class="cleandesk-menu-bottom-div">
        <MenuAvatar
          on:languageModalVisible={() => (langSettingsModalVisible = true)}
          expanded={isMenuExpanded}
          {access_by_id}
          on:loginModalVisible={() => (loginModalVisible = true)}
          on:logoutUser={() => dispatch("closeWidget", true)}
        />
      </div>
    </div>

    {#if isSecondColumnOpen}
      <div
        class="cleandesk-second-column"
        style="width: {$DeviceType === 'desktop' ? '300px' : '100%'}; "
      >
        <ChatListing {access_by_type} />
      </div>
    {/if}

    {#if isThirdColumnOpen}
      <div
        class="cleandesk-third-column"
        style="flex: {isThirdColumnOpen ? 2 : 0}; {isMobileDemo &&
          'width: 100%;'}"
      >
        <div
          on:click={toggleChatPageView}
          class="primary-background-color custom-text-color"
          style="
            position: absolute;
            top: 20vh;
            border-radius: 0 4px 4px 0;
            writing-mode: vertical-lr;
            padding: 12px 4px;
            cursor: pointer;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;
            display: none;"
          on:keypress={toggleChatPageView}
        >
          {!$isPageView ? "Page" : "Chat"}
        </div>
        <div
          class="cleandesk-third-column-chat-div"
          style="height: calc(100% - 152px);"
        >
          {#if !$isPageView}
            <FullScreenChatWindow {isVisible} {access_by_id} />
          {:else}
            <FullScreenPageWindow />
          {/if}
        </div>

        <div
          class="widget-footer"
          style="height: 52px; display: flex; align-items: center; {$DeviceType ===
          'mobile'
            ? 'display: none;'
            : ''}"
        >
          <p style="font-size: 10px; margin: 5px 8px">
            v{AppDefaults.APP_VERSION}
          </p>
          {#if !$isPageView}
            <div style="display: flex;">
              <button
                style="display: flex; align-items: center; border: 0.25px solid #bdbdbd; border-radius: 4px; font-family: inherit;"
                on:click={() => (langSettingsModalVisible = true)}
              >
                <div style="margin-right: 4px; width: 24px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    viewBox="0 -960 960 960"
                    fill="#adadad"
                    width="16"
                    ><path
                      d="m476-80 182-480h84L924-80h-84l-43-122H603L560-80h-84ZM160-200l-56-56 202-202q-35-35-63.5-80T190-640h84q20 39 40 68t48 58q33-33 68.5-92.5T484-720H40v-80h280v-80h80v80h280v80H564q-21 72-63 148t-83 116l96 98-30 82-122-125-202 201Zm468-72h144l-72-204-72 204Z"
                    /></svg
                  >
                </div>
                <p style="font-size: 14px;">{$selectedLanguage}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  fill="#adadad"
                  width="24"><path d="M480-360 280-560h400L480-360Z" /></svg
                >
              </button>

              <!-- <button
              style="display: flex; align-items: center; border: 0.25px solid #bdbdbd; border-radius: 4px; margin-left: 8px; font-family: inherit;"
            >
              <div style="margin-right: 4px; width: 24px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  viewBox="0 -960 960 960"
                  width="16"
                  ><path
                    d="M480-40v-80h280v-40H600v-320h160v-40q0-116-82-198t-198-82q-116 0-198 82t-82 198v40h160v320H200q-33 0-56.5-23.5T120-240v-280q0-74 28.5-139.5T226-774q49-49 114.5-77.5T480-880q74 0 139.5 28.5T734-774q49 49 77.5 114.5T840-520v400q0 33-23.5 56.5T760-40H480ZM200-240h80v-160h-80v160Zm480 0h80v-160h-80v160ZM200-400h80-80Zm480 0h80-80Z"
                  /></svg
                >
              </div>
              <p style="font-size: 14px;">Switch to live chat</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"><path d="M400-280v-400l200 200-200 200Z" /></svg
              >
            </button> -->
            </div>
          {/if}
          <a
            href={AppDefaults.CLEANDESK_URL}
            style="text-decoration:none; color: #000; display: flex; align-items: center; margin-left: auto"
          >
            <p style="padding: 6px; margin: 0px; font-size: 12px">
              Powered by <strong>CleanDesk AI</strong>
            </p>
            <img
              src={DOMAIN + "/ms-icon-70x70.png"}
              style="height: 24px; margin-right: 8px"
              alt=""
            />
          </a>
        </div>
      </div>
    {/if}

    {#if isFourthColumnOpen && $listingMenuItems.length > 0}
      <div
        class="cleandesk-fourth-column"
        style="flex: {isFourthColumnOpen ? 1 : 0};"
      >
        <ListingMenuSection />
      </div>
    {/if}
  </div>
  {#if $DeviceType === "mobile"}
    <MobileBottomSheet on:menuItemClick={menuClicked} />
  {/if}
</div>

<LanguageSettingsModal bind:showModal={langSettingsModalVisible} />

{#if loginModalVisible}
  <LoginModal bind:showModal={loginModalVisible} />
{/if}

<ManageProfileDrawer
  {selectLangMenu}
  drawerVisible={profileDrawerVisible}
  on:closeDrawer={() => (profileDrawerVisible = !profileDrawerVisible)}
  bind:havi={profileDrawerVisible}
  on:logoutUser={() => dispatch("closeWidget", true)}
/>
