<svelte:options tag="message-item" />

<script>
  import axios from "axios";
  import { marked } from "marked";
  import DOMPurify from "dompurify";
  import {
    DOMAIN,
    LEGISLATOR_MANAGEMENT_MODULE,
  } from "../../../config/api-variables";
  import TimeAgo from "../../../common/TimeAgo.svelte";
  import { onMount } from "svelte";
  import { getAuthKey, getPersonOrgOfficeId } from "../../../utils/cookie/user";
  import {
    DeviceType,
    OrganisationDetails,
    userDetails,
    widgetType,
  } from "../../../stores/authStores";
  import {
    generateColorFromNumber,
    getContrastYIQ,
  } from "../../../utils/tools/data-transformers";
  import { createEventDispatcher } from "svelte";
  import { alertMessageService } from "../../../common/AlertMessage/alertMessageService";
  import { comment, each } from "svelte/internal";

  export let message;
  export let listOfUsers;
  export let isLastMessage;
  // export let prevSame;
  export let thumbsDownOptions;
  let prevSame = false;

  let targetText = "";
  let avatarLetter;
  export let isMine = false;
  let list_of_users = [];
  let hasTable = false;
  let messageItem;
  let avatarColor;
  let followOnUserInputList = [];
  let isTypingFinished = false;

  let isOpened = false;

  let i = 0;
  // let txt = "Lorem ipsum dummy text blabla.";
  let speed = 100;
  let output = "";

  const dispatch = createEventDispatcher();

  // function typeWriter() {
  //   if (!isMine && !message?.isMessageOld) {
  //     if (i < message.content.length) {
  //       output += message.content.charAt(i);
  //       i++;
  //       setTimeout(typeWriter, speed);
  //       // output = message.content;
  //       // dispatch("typingFinished", true);
  //     } else {
  //       dispatch("typingFinished", true);
  //     }
  //   } else {
  //     output = message.content;
  //     dispatch("typingFinished", true);
  //   }
  // }

  // function hasMarkdownTable(str) {
  //   const regex = /^\s*[|].*[|]\s*$/gm;
  //   hasTable = regex.test(str);
  //   typeWriter();
  // }

  const customRenderer = new marked.Renderer();

  customRenderer.image = function (href, title, text) {
    return `<img src="${href}" alt="${text}" title="${title || ""}" style="max-width: ${$DeviceType === "desktop" ? "50%" : "100%"};">`;
  };

  marked.setOptions({
    renderer: customRenderer,
  });

  function typeWriter() {
    const messageContent = DOMPurify.sanitize(marked(message.content));

    if (!isMine && !message?.isMessageOld) {
      if (i < messageContent.split(" ").length && !hasTable) {
        output += messageContent.split(" ")[i] + " ";
        i++;
        setTimeout(typeWriter, speed);
      } else if (i < messageContent.split("\n").length && hasTable) {
        output += messageContent.split("\n")[i] + "\n";
        i++;
        setTimeout(typeWriter, 400);
      } else {
        dispatch("typingFinished", true);
        isTypingFinished = true;
      }
    } else {
      output = messageContent;
      dispatch("typingFinished", true);
      isTypingFinished = true;
    }
  }

  let translateLoading = false;
  let backgroundColor = "#ffffff";
  let textColor = "#000000";

  const apiKey = "AIzaSyAfHbFHoYaENq2ysiDwHKAz1yudQBlDlSk"; // Replace with your API key
  const apiUrl =
    "https://translation.googleapis.com/language/translate/v2?key=" + apiKey;

  async function translateItem(lang) {
    translateLoading = true;
    try {
      const response = await axios.post(apiUrl, {
        q: message?.content,
        target: lang,
        key: apiKey,
      });

      targetText = response.data.data.translations[0].translatedText;
      message.content = targetText;
      translateLoading = false;
    } catch (error) {
      translateLoading = false;
    }
  }

  onMount(() => {
    messageItem = message;
    if (messageItem?.follow_on_user_input?.length > 0) {
      followOnUserInputList = messageItem?.follow_on_user_input;
    } else {
      followOnUserInputList = [];
    }
    list_of_users = listOfUsers;
    if (messageItem?.content) {
      // hasMarkdownTable(messageItem?.content);
      typeWriter();
    }
    isOpened = true;
  });

  // $: {
  //   if (messageItem?.color) {
  //     backgroundColor = message?.color;
  //   } else if (!messageItem?.color && !isMine) {
  //     backgroundColor = $OrganisationDetails?.primary_color;
  //   } else {
  //     backgroundColor = "#ffffff";
  //   }
  // }

  $: textColor = getContrastYIQ(backgroundColor);

  $: {
    avatarLetter = message?.title.charAt(0).toUpperCase();
    avatarColor = generateColorFromNumber(message?.person_id);
  }

  const getFileName = (url) => {
    const parts = url.split("/");
    const part = parts[parts.length - 1].replace(/%20/g, " ");
    return part;
  };

  const sendFeedback = (feedback, feedbackComment) => {
    const headers = { Authorization: "Token " + getAuthKey() };
    // dispatch("sendFeedback", feedback);
    // alertMessageService.success("Feedback sent successfully");
    const payload = {
      feedback: feedback,
      message_id: parseInt(message?.id),
      conversation_id: parseInt(message?.ticket_main_id),
      comment: feedbackComment,
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
    };

    // console.log(payload);
    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/feedback/create",
        { ...payload },
        { headers },
      )
      .then((response) => {
        if (response.data.statusCode === "S10001") {
          alertMessageService.success(response?.data?.rows?.feedback_message);
        } else {
          alertMessageService.error(
            "There was a problem sending feedback! Please try again later.",
          );
        }
      })
      .catch((error) => {
        alertMessageService.error("Failed to send feedback");
      });
  };
</script>

{#if message?.content}
  <!-- <div
    class="message-item-container"
    style="{isMine
      ? 'margin: 4px 10px 4px auto; flex-direction: row-reverse;'
      : 'margin: 4px 10px;'}display: flex;"
    > -->
  <div class="message-item-container" style={prevSame && "margin-top: 0;"}>
    <!-- <div class="message-item-container" style="{isMine ? 'margin: 4px 10px 4px auto;' : 'margin: 4px 10px;'} background-color: {backgroundColor}; color: {textColor}"> -->

    <div style="width: 32px;">
      {#if !prevSame}
        <div
          class="chat-header-avatar"
          style="display: flex; align-items: center;"
        >
          {#if message?.title !== "Anonymous " && message?.person_avatar !== "/media/default/profile/person/default.png"}
            <!-- {#if message?.title === "Anonymous " || message?.person_avatar !== "/media/default/profile/person/default.png"} -->
            <!-- <img
              src={DOMAIN + message?.person_avatar}
              alt="A"
              style="height: 24px; border-radius: 50%; {isMine
                ? 'margin-left: 8px;'
                : 'margin-right: 8px;'}"
            /> -->
            <img
              src={DOMAIN + message?.person_avatar}
              alt="A"
              style="height: 24px; border-radius: 50%; margin-right: 8px;'}"
            />
          {:else}
            <!-- <div
              class="avatar"
              style="background-color: {avatarColor}; width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 0.75rem; {isMine
                ? 'margin-left: 8px;'
                : 'margin-right: 8px;'}"
            > -->
            <div
              class="avatar"
              style="background-color: {avatarColor}; width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 0.75rem; margin-right: 8px;"
            >
              {#if message?.title === "Anonymous " || message?.title === $userDetails?.first_name + " " + $userDetails?.last_name}
                Y
              {:else}
                {avatarLetter}
              {/if}
            </div>
          {/if}
        </div>
      {/if}
    </div>

    <div style="width: 100%;">
      {#if !prevSame}
        <!-- <div
          class="chat-header"
          style="display: flex; align-items: center; justify-content: space-between; height: 24px; flex-direction: {isMine
            ? 'row-reverse'
            : 'row'}"
        > -->
        <div
          class="chat-header"
          style="display: flex; align-items: center; justify-content: space-between; height: 24px; flex-direction: row"
        >
          <!-- <div
            style="display: flex; align-items: center; flex-direction: {isMine
              ? 'row-reverse'
              : 'row'}"
          > -->
          <div style="display: flex; align-items: center; flex-direction: row">
            <!-- <div class="chat-header-avatar" style="display: flex; align-items: center;">
        {#if message?.person_avatar !== '/media/default/profile/person/default.png'}
          <img src={DOMAIN + message?.person_avatar} alt="A" style="height: 24px; border-radius: 50%; {isMine ? 'margin-left: 8px;' : 'margin-right: 8px;'}">
        {:else}
          <div class="avatar" style="background-color: {avatarColor}; width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 0.75rem; {isMine ? 'margin-left: 8px;' : 'margin-right: 8px;'}">
            {#if message?.title === 'Anonymous '}
              Y
            {:else}
              {avatarLetter}
            {/if}
          </div>
        {/if}
      </div> -->

            <div
              class="header-profile-name"
              style="font-size: 16px; font-weight: 600"
            >
              {#if message?.title === "Anonymous " || message?.title === $userDetails?.first_name + " " + $userDetails?.last_name}
                You
              {:else}
                {message?.title.toLowerCase()}
              {/if}
            </div>
          </div>
          <div style="display: flex;">
            <div class="time-ago-style" style="padding: 0 8px;">
              <TimeAgo timestamp={message?.created_at} />
            </div>
          </div>

          <!-- <div class="dropdown" style="margin-left: auto">
      <button class="more-button-style">
        <svg style="height: 14px; fill: {textColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{opacity:0;}.cls-2{}</style></defs><title>more</title><g id="Layer_2" data-name="Layer 2"><g id="more-vertical"><g id="more-vertical-2" data-name="more-vertical"><rect class="cls-1" width="24" height="24" transform="translate(0 24) rotate(-90)"/><circle class="cls-2" cx="12" cy="12" r="2"/><circle class="cls-2" cx="12" cy="5" r="2"/><circle class="cls-2" cx="12" cy="19" r="2"/></g></g></g></svg>
      </button>
      <div class="dropdown-content" style="left:-100px; padding: 4px; border-radius: 8px">
        <button class="dropdown-button {translateLoading ? 'disable-button' : ''}" disabled={translateLoading} on:click={() => translateItem('en')}>Translate to english</button>
        <button class="dropdown-button {translateLoading ? 'disable-button' : ''}" disabled={translateLoading} on:click={() => translateItem('hi')}>Translate to hindi</button>
        <button class="dropdown-button {translateLoading ? 'disable-button' : ''}" disabled={translateLoading} on:click={() => translateItem('ta')}>Translate to tamil</button>
      </div>
    </div> -->
        </div>
      {/if}

      <div
        class="message-bubble"
        style=" background-color: {backgroundColor}; color: {textColor};"
      >
        <div class="message-item-body" style="">
          <p>
            {@html output}
            <!-- <Markdown markdownContent={output} /> -->
          </p>
        </div>
      </div>

      {#if message?.media_type === "image"}
        <div
          style={$DeviceType === "mobile" || $widgetType === "chatbox"
            ? "width: 100%; max-width: 600px;"
            : "width: 50%;"}
        >
          <img
            src={DOMAIN + message?.media_url}
            alt="media"
            style="width: 100%; height: auto;"
          />
        </div>
      {:else if message?.media_type === "pdf" || message?.media_type === "doc"}
        <div
          style="{$DeviceType === 'mobile' || $widgetType === 'chatbox'
            ? 'width: 100%; max-width: 600px;'
            : 'width: 50%;'} display: flex; align-items: center;"
        >
          <img
            src={DOMAIN + "/images/KnowlegdeBaseImages/pdf.png"}
            style="height: 30px;"
            alt="pdf"
          />
          <a href={DOMAIN + message?.media_url} target="_blank">
            {getFileName(message?.media_url)}
          </a>
        </div>
      {:else if message?.media_type === "video"}
        <div
          style={$DeviceType === "mobile" || $widgetType === "chatbox"
            ? "width: 100%; max-width: 600px;"
            : "width: 50%;"}
        >
          {#if message?.media_url}
            <video controls style="width: 100%; height: auto;">
              <source src={DOMAIN + message?.media_url} type="video/mp4" />
              <track kind="captions" />
              Your browser does not support the video tag.
            </video>
          {/if}
        </div>
      {/if}

      <div style="display: {!isMine ? 'block' : 'none'}">
        <!-- <button class="message-action-buttons">
          <svg
            class="message-action-icons"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            ><path
              d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z"
            /></svg
          >
        </button> -->
        <button
          class="message-action-buttons"
          on:click={() => {
            navigator.clipboard.writeText(message?.content);
            alertMessageService.success("Copied to clipboard");
          }}
        >
          <svg
            class="message-action-icons"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            fill="#adadad"
            ><path
              d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"
            /></svg
          >
        </button>
        <button
          class="message-action-buttons"
          on:click={() => sendFeedback(1, null)}
        >
          <svg
            class="message-action-icons"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            fill="#adadad"
            ><path
              d="M720-120H280v-520l280-280 50 50q7 7 11.5 19t4.5 23v14l-44 174h258q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14Zm-360-80h360l120-280v-80H480l54-220-174 174v406Zm0-406v406-406Zm-80-34v80H160v360h120v80H80v-520h200Z"
            /></svg
          >
        </button>

        <div class="dropdown" style="margin-left: auto">
          <button class="more-button-style">
            <svg
              class="message-action-icons"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="#adadad"
              ><path
                d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z"
              /></svg
            >
          </button>
          <div
            class="dropdown-content"
            style="left:-100px; padding: 4px; border-radius: 8px; bottom: 0; min-width: 140px"
          >
            {#each thumbsDownOptions as item}
              <button
                class="dropdown-button"
                style="text-align: left;"
                on:click={() => sendFeedback(0, item.options)}
              >
                {item.options}
              </button>
            {/each}
          </div>
        </div>

        <!-- <button class="message-action-buttons" on:click={() => sendFeedback(0)}>
          <svg
            class="message-action-icons"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            fill="#adadad"
            ><path
              d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z"
            /></svg
          >
        </button> -->
        <!-- <button class="message-action-buttons">
          <svg
            class="message-action-icons"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            ><path
              d="M640-440v-80h160v80H640Zm48 280-128-96 48-64 128 96-48 64Zm-80-480-48-64 128-96 48 64-128 96ZM120-360v-240h160l200-200v640L280-360H120Zm280-246-86 86H200v80h114l86 86v-252ZM300-480Z"
            /></svg
          >
        </button> -->
      </div>

      {#if isLastMessage && followOnUserInputList.length > 0 && isTypingFinished}
        <div style="display: flex; flex-direction: column; text-align: left;">
          {#each followOnUserInputList as item}
            <button
              style="
              margin: 2px 0;
              border: 0.5px solid #bdbdbd;
              padding: 4px;
              border-radius: 4px;
            "
              on:click={() => dispatch("followOnUserInput", item)}
            >
              <div
                style="display: flex; align-items: center; justify-content: space-between;"
              >
                <p style="text-align: left; font-size: 14px; color: #5f6368;">
                  {item}
                </p>
                <div style="display: flex;">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14px"
                    viewBox="0 -960 960 960"
                    width="14px"
                    fill="#5f6368"
                    ><path
                      d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"
                    /></svg
                  >
                </div>
              </div>
            </button>
          {/each}
        </div>
      {/if}
    </div>
  </div>
{/if}
