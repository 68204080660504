export const AppDefaults = {
  COMPANY_NAME: "Resurge Technologies Pvt. Ltd.",
  APP_NAME: "CleanDesk Widget",

  APP_VERSION: "2.0.78",
  APP_BUILD: "148",

  CLEANDESK_URL: "https://www.cleandesk.ai/",
};

export const HELPDESK = {
  STATUS: "status",
  PRIORITY: "priority",
  CATEGORY: "category",
  SKILL: "skill",
  TIER: "tier",

  UNASSIGNED: "Unassigned",
  ASSIGNED: "Assigned",
  ONHOLD: "On Hold",
  RESOLVED: "Resolved",
  REOPEN: "Reopen",
  CLOSED: "Closed",

  BOT_REQ_OFFICE: "office",
  BOT_REQ_CATEGORY: "category",
  BOT_REQ_MEDIA: "media",
  BOT_REQ_LOCATION: "location",
};

export const HELPDESK_COLORS = {
  UNASSIGNED: "#FFF",
  ASSIGNED: "#E2E3E5",
  ONHOLD: "#FFF3CD",
  RESOLVED: "#D1ECF1",
  REOPEN: "#F8D7DA",
  CLOSED: "#D4EDDA",
};
